import './App.css';
import TextDetector from './TextDetector';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from './AboutUs';


function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
        <Route path="/" element={<TextDetector />} />
        <Route path="/about" element={<AboutUs />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
