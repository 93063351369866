import "./web.css";
import React from "react";

const AboutUs = () => {
  return (
    <>
      <h1>About Us</h1>
      <div className="about-us">
        <p>
          Welcome to our website! At Genslator, we are dedicated to providing
          high-quality translation services tailored to meet your needs. Our
          team consists of skilled linguists and professionals who strive to
          deliver accurate and reliable translations for various languages and
          industries.
        </p>
        <p>
          Whether you need document translation, website localization, or
          interpretation services, we've got you covered. We understand the
          importance of effective communication in today's globalized world, and
          we're here to bridge the language gap for you.
        </p>
        <p>
          Feel free to explore our website to learn more about our services and
          how we can assist you with your language needs. If you have any
          questions or would like to discuss your project requirements, don't
          hesitate to contact us. We look forward to working with you!
        </p>
        <p>
          Genslator was founded by <span className="founder-name">Benedikt von Bremen</span>, a visionary student with
          a deep passion for languages and technology. student with a deep
          passion for both languages and technology. With a fervent desire to
          reshape the translation industry, Benedikt founded Genslator, infusing
          the company with his innovative spirit and unwavering commitment to
          excellence.
        </p>
      </div>
    </>
  );
};

export default AboutUs;
