import "./web.css";
import React, { useState } from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router

const GenderLanguageDetector = () => {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");

  const genderLanguageMap = {
    Student: "Studi",
    Studentin: "Studierende",
    Studenten: "Studierende",
    Studentinnen: "Studierende",
    Abbrecherquote: "Abbruchquote",
    Akteure: ["Beteiligte", "Agierende", "Handelnde", "Aktive"],
    Anlieger: ["Anliegende", "anwohnende Personen", "Anwohnende"],
    Arbeiterklasse: "arbeitende Klasse",
    Arbeitgeberattraktivität: "Arbeitsplatzattraktivität",
    Arbeitgebermarke: "Unternehmensmarke; Employer Branding",
    Arbeitgebermarketing: "Marketing für Arbeitgebende",
    Arbeitgebervorteile: "Firmenvorteile; Unternehmensvorteile",
    Azubi:
      "Nachwuchskraft; Person in Ausbildung; auszubildende Person; lernende Person",
  };

  const detectAndReplaceGenderLanguage = (text) => {
    let modifiedText = text;
    Object.keys(genderLanguageMap).forEach((word) => {
      const replacements = genderLanguageMap[word];
      const regex = new RegExp(`\\b${word}\\b`, "g");
      const replacedText =
        replacements instanceof Array ? replacements[0] : replacements;
      const color = replacements instanceof Array ? "#8ec3b3" : "#8ec3b3";
      modifiedText = modifiedText.replace(
        regex,
        `<span style="color: ${color};">${replacedText}</span>`
      );
    });
    return modifiedText;
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputText(text);
    const modifiedText = detectAndReplaceGenderLanguage(text);
    setOutputText(modifiedText);
  };

  return (
    <>
      <div class="navi-title-container">
        <div className="navi-title">
          <h1 className="dot">.</h1>
          <h1>Genslator</h1>
          <Link className="link" to="/about">
            About Us
          </Link>
        </div>
      </div>
      <div className="centered-container">
        <textarea
          placeholder="Enter your text here..."
          value={inputText}
          onChange={handleInputChange}
          rows={5}
          cols={50}
          className="textarea-container"
        />
        <h3>Output:</h3>
        <div
          className="output-container"
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{ __html: outputText }}
        ></div>
      </div>
    </>
  );
};

export default GenderLanguageDetector;
